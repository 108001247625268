.footer {
  font-family: "Istok Web", sans-serif;
  background-color: var(--white-color);
}

.footer-shadow {
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.footer-btn {
  font-size: 17px;
  border: none;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.2s;
  position: relative;
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.footer-btn::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--black-color);
  transition: width 0.3s;
}

.footer-btn:hover::after {
  width: 100%;
}

.footer-btn:hover {
  color: var(--black-color);
  transform: scale(1.1);
}

.footer-antcolony-link {
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
  transition:
    transform 0.2s,
    color 0.2s;
  position: relative;
  overflow: hidden;
}

.footer-antcolony-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width 0.3s;
}

.footer-antcolony-link:hover::after {
  width: 100%;
}

.footer-antcolony-link:hover {
  transform: scale(1.1);
}

.animate-link {
  transition: transform 0.2s;
}

.animate-link:hover {
  transform: scale(1.1);
}

.social-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background-color: black;
  border-radius: 20%;
  color: #fdfdfd;
  padding: 5px;
}

.footer-btn.active {
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .footer-btn {
    font-size: 15px;
    padding: 0.25rem 0.5rem;
  }

  .social-icon {
    width: 30px;
    height: 30px;
  }

  .footer .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer .nav {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer .nav-link {
    padding: 0.5rem;
  }

  .footer p {
    font-size: 14px;
  }
}
