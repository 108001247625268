#root {
  font-family: "Istok Web", sans-serif;
}
.list-title {
  color: var(--primary-color);
  font-size: 1.2rem;
}
.list-details {
  font-size: 1rem;
}

.list-filter {
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Istok Web", sans-serif;
  border: none;
  transition: transform 0.3s;
}

.list-filter:hover {
  background-color: #f5f5f5;
  transform: scale(1.02);
}

.popular-filter {
  font-weight: semibold;
  font-size: 22px;
}

.popular-btn {
  font-weight: semibold;
  font-size: 14px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
  color: var(--white-color);
  transition: transform 0.3s;
}
.popular-btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.source-kind-btn {
  font-weight: semibold;
  font-size: 14px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--light-gray-color);
  color: var(--gray-color);
  transition: transform 0.3s;
}

.source-kind-btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.source-kind-detail-btn {
  font-weight: semibold;
  font-size: 14px;
  background-color: var(--light-gray-color);
  color: var(--gray-color);
  transition: transform 0.2s;
}

.source-kind-detail-btn:hover {
  background-color: var(--white-color);
  transform: scale(1);
}

.next-prev-btn {
  font-family: "Istok Web", sans-serif;
  font-weight: semibold;
  font-size: 14px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--gray-color);
  color: var(--white-color);
  transition: transform 0.3s;
}

.next-prev-btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}
.next-prev-btn:disabled {
  background-color: var(--gray-color);
  color: var(--white-color);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
}
.next-prev-btn:active {
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .list-title {
    font-size: 1em;
  }

  .list-details {
    font-size: 0.8em;
  }

  .popular-filter {
    font-weight: semibold;
    font-size: 20px;
  }
  .popular-btn {
    font-size: 12px;
  }
  .source-kind-detail-btn {
    font-size: 0.8em;
  }
}
