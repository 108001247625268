.tag-btn {
  font-family: "Istok Web", sans-serif;
  font-size: 12px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
}

.detail-back-btn {
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
}
