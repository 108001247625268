.bg-color {
  background-color: var(--white-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.header-btn {
  font-family: "Istok Web", sans-serif;
  font-weight: semibold;
  color: var(--black-color);
  text-decoration: none;
  font-size: 17px;
  border-radius: 5px;
  padding: 5px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
  overflow: hidden;
}

.header-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width 0.3s;
}

.header-btn:hover::after {
  width: 100%;
}

.header-btn:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}

.header-btn.active {
  font-weight: bold;
  color: var(--primary-color);
}
