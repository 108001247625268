:root {
  --primary-color: #217821;
  --secondary-color: #66ff00;
  --tertiary-color: #7fff2a;
  --quaternary-color: #07b585;
  --quinary-color: #ccffaa;
  --gray-color: #454545;
  --light-gray-color: #dcdcdc;
  --white-color: #ffffff;
  --black-color: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Istok Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gray-color {
  color: var(--gray-color);
}
.primary-color {
  color: var(--primary-color);
}

.infoSnackbar {
  background-color: green !important;
  color: white !important;
}
