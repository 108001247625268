.filter-btn {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.shadow-filter {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.filter-btn:hover {
  background-color: var(--primary-color);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  color: var(--white-color);
}
.shadow-filter {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.filter-btn:hover {
  background-color: var(--primary-color);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  color: var(--white-color);
}
.filter-btn-icon {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--white-color);
  color: var(--primary-color);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
